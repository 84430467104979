@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --fontfamily_Public: 'Public Sans', sans-serif;
    --blackcolor: #212B36;
    --fullblackcolor: #000000;
    --whitecolor: #ffffff;
    --F1F1F1color: #f1f1f1;
    --bluecolor: #1583c4;
    --lightbluecolor: #919EAB;
    --lightgreencolor: rgba(0, 171, 85, 0.08);
    --greencolor: #00AB55;
    --mainorangecolor: #F99A0E;
    --mainbluecolor: #1262AF;

    --primarylightercolor: #C8FACD;
    --infolightercolor: #CAFDF5;
    --warninglightercolor: #FFF5CC;
    --errorlightercolor: #FFE9D5;

    --solidcolor1: #005249;
    --solidcolor2: #003768;
    --solidcolor3: #7A4100;
    --solidcolor4: #7A0916;

    --yellowcolor: #B76E00;
    --redcolor: #CD0000;
    --lightredcolor: rgba(255, 86, 48, 0.16);
    --paragraphcolor: #637381;
    /*-----------------------RGBA color----------------------*/
    --RGBAcolor1: rgba(145, 158, 171, 0.08);
    --RGBAcolor2: rgba(255, 86, 48, 0.16);
    --RGBAcolor3: rgba(54, 179, 126, 0.16);
    /*-----------------------gradiant color----------------------*/
    --gradiantcolor1: linear-gradient(135deg, rgba(0, 184, 217, 0) 0%, rgba(0, 184, 217, 0.24) 97.35%);
    --gradiantcolor2: linear-gradient(135deg, rgba(255, 171, 0, 0) 0%, rgba(255, 171, 0, 0.24) 97.35%);
    --gradiantcolor3: linear-gradient(135deg, rgba(255, 86, 48, 0) 0%, rgba(255, 86, 48, 0.24) 97.35%);
    --seabluecolor: #006994;
    --bs-btn-hover-bg: #f6f7f8;
}

// .input-container input {
//     border: none;
//     box-sizing: border-box;
//     outline: 0;
//     padding: .75rem;
//     position: relative;
//     width: 100%;
// }

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

// input[type="time"]::-webkit-calendar-picker-indicator {
//     background: transparent;
//     bottom: 0;
//     color: transparent;
//     cursor: pointer;
//     height: auto;
//     left: 0;
//     position: absolute;
//     right: 0;
//     top: 0;
//     width: auto;
// }

.fontfamily {
    font-family: var(--fontfamily_Public) !important;
}

.fontSans {
    font-family: 'Public Sans';
}

.whitebg {
    background-color: var(--whitecolor) !important;
}

.mainorange {
    color: var(--mainorangecolor) !important;
}

.mainblue {
    color: var(--mainbluecolor) !important;
}

.mainbluebg {
    background: var(--mainbluecolor) !important;
}

.whitetext {
    color: var(--whitecolor) !important;
}

.RGBAcolor1bg {
    background-color: var(--RGBAcolor1) !important;
}

.RGBAcolor1bg .form-label {
    background-color: rgb(246 247 248) !important;
}

.RGBAcolor1text {
    color: var(--RGBAcolor1) !important;
}

.RGBAcolor2bg {
    background-color: var(--RGBAcolor2) !important;
}

.RGBAcolor2text {
    color: var(--RGBAcolor2) !important;
}

.RGBAcolor3bg {
    background-color: var(--RGBAcolor3) !important;
}

.RGBAcolor3text {
    color: var(--RGBAcolor3) !important;
}

.gradiant1bg {
    background: var(--gradiantcolor1) !important;
}

.gradiant1text {
    color: var(--gradiantcolor1) !important;
}

.gradiant2bg {
    background: var(--gradiantcolor2) !important;
}

.gradiant2text {
    color: var(--gradiantcolor2) !important;
}

.gradiant3bg {
    background: var(--gradiantcolor3) !important;
}

.gradiant3text {
    color: var(--gradiantcolor3) !important;
}

.infolighterbg {
    background-color: var(--infolightercolor) !important;
}

.infolightertext {
    color: var(--infolightercolor) !important;
}

.successcolorbg {
    background-color: var(--greencolor) !important;
}

.solidcolor1bg {
    background-color: var(--solidcolor1) !important;
}

.solidcolor1text {
    color: var(--solidcolor1) !important;
}

.solidcolor2bg {
    background-color: var(--solidcolor2) !important;
}

.solidcolor2text {
    color: var(--solidcolor2) !important;
}

.solidcolor3bg {
    background-color: var(--solidcolor3) !important;
}

.solidcolor3text {
    color: var(--solidcolor3) !important;
}

.solidcolor4bg {
    background-color: var(--solidcolor4) !important;
}

.solidcolor4text {
    color: var(--solidcolor4) !important;
}

.primarylighterbg {
    background-color: var(--primarylightercolor) !important;
}

.primarylightertext {
    color: var(--boxgreencolor) !important;
}

.warninglighterbg {
    background-color: var(--warninglightercolor) !important;
}

.warninglightertext {
    color: var(--warninglightercolor) !important;
}

.errorlighterbg {
    background-color: var(--errorlightercolor) !important;
}

.errorlightertext {
    color: var(--errorlightercolor) !important;
}

.blackbg {
    background-color: var(--blackcolor) !important;
}

.blacktext {
    color: var(--blackcolor) !important;
}

.fullblackbg {
    background-color: var(--fullblackcolor) !important;
}

.fullblacktext {
    color: var(--fullblackcolor) !important;
}

.bluebg {
    background-color: var(--bluecolor) !important;
}

.bluetext {
    color: var(--bluecolor) !important;
}

.F1F1F1bg {
    background-color: var(--F1F1F1color) !important;
}

.F1F1F1text {
    color: var(--F1F1F1color) !important;
}

.lightbluebg {
    background-color: var(--lightbluecolor) !important;
}

.lightbluetext {
    color: var(--lightbluecolor) !important;
}

.lightgreenbg {
    background-color: var(--lightgreencolor) !important;
}

.lightgreentext {
    color: var(--lightgreencolor) !important;
}

.greenbg {
    background-color: var(--greencolor) !important;
}

.bluebg {
    background-color: var(--bluecolor) !important;
}

.greentext {
    color: var(--greencolor) !important;
}

.seabluebg {
    background-color: var(--seabluecolor) !important;
}

.seabluetext {
    color: var(--seabluecolor) !important;
}

.yellowbg {
    background-color: var(--yellowcolor) !important;
}

.yellowtext {
    color: var(--yellowcolor) !important;
}

.redbg {
    background-color: var(--redcolor) !important;
}

.redtext {
    color: var(--redcolor) !important;
}

.lightredbg {
    background-color: var(--lightredcolor) !important;
}

.lightredtext {
    color: var(--lightredcolor) !important;
}


.paragraphbg {
    background-color: var(--paragraphcolor) !important;
}

.paragraphtext {
    color: var(--paragraphcolor) !important;
}

.object-cover {
    object-fit: cover;
}

.cursor-pointer {
    cursor: pointer;
}

.rotate90 {
    rotate: 90deg;
    transition: all 0.6s;
}

.droparrorw {
    transition: all 0.6s;
}

.sub-menu {
    transition-duration: 300ms;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    height: auto;
}

.listdot {
    &::before {
        content: "";
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: rgb(145, 158, 171);
        transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        display: block;
    }
}

.listdot-active {
    &::before {
        content: "";
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: var(--greencolor);
        transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        display: block;
        transform: scale(2);
    }
}

/*-----------------------Reset Styles----------------------*/

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

// 
html {
    min-height: 100%;
    position: relative;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    padding: 0;
    margin: 0;
    background: var(--whitecolor);
    min-height: 100vh;
    font-family: var(--fontfamily_Public);
    overflow: overlay;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.h30 {
    height: 30px;
}

.h40 {
    height: 40px;
}

// ::selection {
//     color: var(--greencolor);
//     background-color: transparent;
// }

ul,
ol,
li {
    margin: 0;
    padding: 0;
}

.btn:focus {
    box-shadow: none;
}

// 
.form-control,
.form-select:focus,
.form-control:focus {
    box-shadow: none;
    -moz-box-shadow: none;
}

.form-control {
    box-shadow: none;
    background: none;
}

.form-control:focus {
    outline: 0;
    box-shadow: none;
}

.form-select:focus {
    outline: 0;
    box-shadow: none;
}

// 
.form-check {
    display: flex;
    align-items: center;
}

.form-check-input {
    width: 20px;
    height: 20px;
    margin-top: 0;

    &:checked {
        background-color: var(--bluecolor);
        border-color: var(--bluecolor);
    }

    &:focus {
        box-shadow: none;
    }
}

.form-check-label {
    margin-left: 10px;
    position: relative;

    &:hover {
        text-decoration: underline;
    }

    a {
        font-size: 14px;
        font-weight: 500;
        color: var(--blackcolor);
        text-decoration: none;
    }
}

[type=button]:focus,
a:active,
a:focus,
a:visited,
button::-moz-focus-inner,
input[type=reset]::-moz-focus-inner,
input[type=button]::-moz-focus-inner,
input[type=submit]::-moz-focus-inner,
input[type=file]>input[type=button]::-moz-focus-inner,
select::-moz-focus-inner {
    outline: 0;
    // font-family: var(--fontfamily_Nunito);
}

input,
.form-control:focus,
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
    outline-width: 0;
    outline-color: transparent;
    box-shadow: none;
    outline-style: none;
}


textarea {
    resize: none;
    overflow-x: hidden;
    font-family: var(--fontfamily_Public);
}

// 
h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--blackcolor);
    margin-bottom: 0;
    margin-top: 0;
    font-family: var(--fontfamily_Public);
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 600;
    color: var(--blackcolor);
    font-family: var(--fontfamily_Public);
}

.text-decoration {
    text-decoration: none !important;
}

a {
    text-decoration: unset;
}

.dropdown-toggle {
    &.btn {
        border: none;
    }

    &::after {
        display: none;
    }

    img {
        min-width: 4px;
    }
}

.dropdown-menu {
    border-color: var(--F1F1F1color);
    padding: 0;
    box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);

    .dropdown-item {
        font-size: 14px;
        color: var(--blackcolor);

        &.active,
        &:active {
            background-color: #e9ecef;
        }
    }
}

// wrapper
.wrapper {
    height: 100vh;
    width: 100%;
}

/*-----------------------content----------------------*/

.content_page {
    margin-left: 280px;
    // overflow: hidden;
    // padding: 16px;
    padding-bottom: 120px;
    min-height: 100%;
    transition: all 0.3s;

    &.active {
        margin-left: 280px;
    }
}

@media(max-width:767px) {
    .content_page {
        margin-left: 0;

        &.active {
            margin-left: 80px;
        }
    }
}


// main_heading
.main_heading {
    position: relative;

    h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: var(--blackcolor);
    }
}

/*-----------------------top header----------------------*/

.header {
    // background: linear-gradient(270deg, var(--whitecolor), var(--whitecolor));
    padding: 0;
    position: fixed;
    left: 0;
    right: 0;
    height: 72px;
    z-index: 999;
    top: 0;
    display: flex;
    width: 100%;
    align-items: center;
    backdrop-filter: blur(6px);
    background-color: rgba(255, 255, 255, 0.8);
    transition: height 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &.active {
        .logo_box {
            width: 80px;
        }

        .monogram {
            display: none !important;
        }
    }

    .navbar {
        padding: 0 0;
        min-height: 100%;

        .logoWrapp {
            width: 100%;
            height: 100%;
            max-width: 280px;
            min-width: 280px;
            display: flex;
            align-items: center;
            padding-left: 15px;
            border-right: 1px dashed rgba(145, 158, 171, 0.24);

            .btn {
                border: none;
                border-radius: 0;

                &:focus {
                    box-shadow: none;
                }
            }

            .sidebarCollapse {
                width: 100%;
                height: 100%;
                max-height: 35px;
                min-height: 35px;
                min-width: 35px;
                max-width: 35px;
                border-radius: 100px;
                border: solid 1px var(--RGBAcolor2);
                background-color: var(--RGBAcolor1);
            }
        }

        // input group
        .input-group {
            border: 1px solid rgba(102, 102, 102, 0.16);
            border-radius: 10px;
            // background-color: var(--whitecolor);
            height: 52px;
            width: 350px;

            .input-group-text {
                background-color: transparent;
                border: none;
            }

            .form-control {
                border: none;
                font-size: 12px;
                color: rgba(0, 0, 0, 0.50);

            }
        }

        // profileLogin
        .dropdown.profileLogin {
            .dropdown-toggle {
                &.btn {
                    border: none;
                    padding: 0;
                    width: 100%;
                    height: 100%;
                    max-width: 40px;
                    min-width: 40px;
                    min-height: 40px;
                    max-height: 40px;
                    overflow: hidden;
                    border-radius: 100px;
                }

                &::after {
                    display: none;
                }
            }

            .dropdown-menu {
                border-color: var(--F1F1F1color);
                padding: 0;
                box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);

                .dropdown-item {
                    font-size: 14px;
                    color: var(--blackcolor);

                    &.active,
                    &:active {
                        background-color: #e9ecef;
                    }
                }
            }
        }
    }
}

header.webheader {
    // padding: 10px 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    transition: all 0.5s;
    background: transparent;

    .scrollheader {
        transition: all 0.5s;
        background: white !important;
    }

    .scrollheader.navbar-light {
        .navbar-nav {
            .nav-link {
                font-style: normal;
                font-weight: 500;
                color: var(--blackcolor);
                padding: 8px 15px 8px 15px;
                font-size: 14px;
                position: relative;
                line-height: 21px;
                transition: all 0.3s;


                &:hover {
                    &::after {
                        opacity: 1;
                        bottom: 0;
                    }

                    color: var(--mainbluecolor);
                }

                &::after {
                    background-color: var(--mainbluecolor);
                }
            }
        }
    }

    .navbar-light {
        .navbar-nav {
            .nav-link {
                font-style: normal;
                font-weight: 500;
                color: var(--whitecolor);
                padding: 8px 15px 8px 15px;
                font-size: 14px;
                position: relative;
                line-height: 21px;
                transition: all 0.3s;


                &:hover {
                    &::after {
                        opacity: 1;
                        bottom: 0;
                    }

                    color: var(--mainorangecolor);
                }

                &::after {
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    content: '';
                    width: 100%;
                    bottom: -5px;
                    opacity: 0;
                    background-color: var(--mainorangecolor);
                    height: 2px;
                    transition: all .6s;
                }

                &.active {
                    color: var(--mainbluecolor);

                    &::after {
                        opacity: 1;
                        bottom: 0;
                    }
                }
            }

            // .show>.nav-link,
            // .nav-link.active {
            // 	color: var(--greencolor);
            // 	&::after{
            // 		background: var(--greencolor);
            // 	}
            // }
        }
    }
}

@media(min-width:1440px) {
    .header {
        .navbar {
            .input-group {
                width: 472px;
            }
        }
    }
}

@media(max-width:767px) {
    .header {
        .navbar {
            .logoWrapp {
                max-width: 80px;
                min-width: 80px;
                text-align: center;
                border-right: none;
                padding-left: 10px;
            }
        }
    }
}

/*-----------------------card----------------------*/
.card {
    border: none;
    border-radius: 16px;
}

/*-----------------------topBoxes----------------------*/
.topBoxes {
    .card {
        border-radius: 16px;
        padding: 20px;
        border: none;
        min-height: 242px;
        max-height: 242px;
        height: 100%;
        display: flex;
        justify-content: end;
        align-items: center;
        transition: all .6s;
        background-size: cover;

        &:hover {
            box-shadow: inset 0 -3em 3em rgba(225 225 225 / 10%), 0 0 0 2px rgb(255, 255, 255),
                0 0 1em rgba(0, 0, 0, 0.3);

            .photo {
                transform: scale(1.1);
            }

            .title {
                transform: scale(1.1);
            }
        }

        .title {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            transition: all 0.6s;
            padding: 4px 8px;
            background: white;
            border-radius: 8px;
        }

        .photo {
            width: 100%;
            height: 100%;
            max-width: 72px;
            min-width: 72px;
            max-height: 72px;
            min-height: 72px;
            border-radius: 100px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all .6s;
        }
    }
}

.form-check-input:checked {
    background-color: var(--greencolor);
    border-color: var(--greencolor);
}

/*-----------------------BreadCrumbLinkks----------------------*/
.BreadCrumbLinkks {
    .breadcrumb {
        .breadcrumb-item {
            display: flex;
            align-items: center;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;

            &:first-child::before {
                display: none;
            }

            &::before {
                content: '';
                background-color: var(--blackcolor);
                width: 4px;
                height: 4px;
                border-radius: 100px;
                padding: 0;
                margin: 0 6px;
            }

            a {
                text-decoration: none;
                color: var(--blackcolor);
            }
        }
    }
}

/*-----------------------AllForm----------------------*/
.AllForm {
    .form-group {
        position: relative;

        .form-label {
            position: absolute;
            font-style: normal;
            font-weight: 400;
            z-index: 1;
            margin: 0;
            left: 15px;
            padding: 0 8px;
            background-color: var(--whitecolor);
            top: -5px;
            font-size: 12px;
            color: #919EAB;
            line-height: 12px;
        }

        .form-control {
            border-radius: 8px;
            height: 56px;

            // color: #919EAB;
            &:focus {
                background: transparent;
                outline: none;
            }
        }

        .height-unset {
            height: unset !important;
        }

        .form-control::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #d7d7d7;
            opacity: 1;
            /* Firefox */
        }

        .form-control:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #d7d7d7;
        }

        .form-control::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #d7d7d7;
        }

        .form-select {
            height: 56px;
            border-radius: 8px;

            // color: #000000;
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }
}

/*-----------------------sidebar----------------------*/

.left_side_menu {
    width: 100%;
    max-width: 280px;
    background: var(--whitecolor);
    bottom: 0;
    padding: 0 0 0 0;
    position: fixed;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    top: 70px;
    border-right: 1px dashed rgba(145, 158, 171, 0.24);

    &.active {
        width: 280px;

        .hidetext {
            display: block;
            margin-left: 15px;
        }

        .space {
            margin-right: 15px;
        }
    }

    // -webkit-box-shadow: 0 0 24px 0 rgb(0 0 0 / 6%), 0 1px 0 0 rgb(0 0 0 / 2%);
    // box-shadow: 0 0 24px 0 rgb(0 0 0 / 6%), 0 1px 0 0 rgb(0 0 0 / 2%);
    z-index: 99;

    .slimScrollDiv {
        height: 100%;
        padding: 0 15px;
        overflow-y: overlay;
        overflow-x: auto;

        .nav-item {
            .nav-link {
                display: flex;
                align-items: center;
                color: var(--paragraphcolor);
                font-size: 14px;
                font-weight: 600;
                padding: 10px 15px 10px 15px;
                border-radius: 10px;

                // &:hover,
                // &:focus {
                &:hover {
                    background: var(--lightgreencolor);
                    color: var(--greencolor);

                    img {
                        filter: brightness(1) sepia(100) hue-rotate(110deg) saturate(330%);
                    }
                }

                .space {
                    margin-right: 0;
                }

                .badge {
                    background-color: var(--RGBAcolor2);
                    color: var(--redcolor);
                }
            }

            .activenav {
                background: var(--lightgreencolor);
                color: var(--greencolor);

                img {
                    filter: brightness(1) sepia(100) hue-rotate(110deg) saturate(330%);
                }
            }

            .show>.nav-link,
            .nav-link.active {
                background: var(--lightbluedarkcolor);
                color: var(--seabluecolor);
                border-left: solid 2px var(--seabluecolor);

                img {
                    filter: brightness(1) sepia(100%) hue-rotate(0deg) saturate(0);
                }
            }
        }

        // accordian
        .accordion {
            .accordion-item {
                border-radius: 10px;
                border: none;

                .accordion-button {
                    box-shadow: none;
                    color: var(--paragraphcolor);
                    font-size: 14px;
                    font-weight: 600;
                    padding: 10px 15px;

                    &:not(.collapsed) {
                        background: var(--lightgreencolor);
                        color: var(--greencolor);

                        img {
                            filter: brightness(1) sepia(100) hue-rotate(110deg) saturate(330%);
                        }
                    }
                }
            }
        }
    }
}


@media(max-width:767px) {
    .left_side_menu {
        max-width: 80px;

        .hidetext {
            display: none;
        }

        .nav-link {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

        &.active {
            max-width: 0px;

            .hidetext {
                display: none;
            }

            .slimScrollDiv {
                padding: 0;
            }
        }

        .slimScrollDiv {
            padding: 0;

            .nav-link {
                justify-content: center;
            }
        }
    }

    .AllForm {
        .form-group {
            .form-control {
                height: 40px;
            }
        }
    }

    .h-56 {
        height: 40px !important;
    }

    .mainhedding {
        font-size: 50px !important;
    }

    .bannerbox {
        height: 780px !important;
        padding-top: 65px;
    }
}

/*=========== {___listContent___} ===========*/
.listContent {
    position: relative;

    .card {
        border: none;
        box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
        border-radius: 16px;

        .card-header {
            border: none;
            background-color: transparent;
            margin: 0;

            h4 {
                font-weight: 700;
                font-size: 18px;
                line-height: 28px;
                color: var(--blackcolor);
            }
        }

        .card-footer {
            border-top: 1px solid rgba(145, 158, 171, 0.24);
            background-color: transparent;
            margin: 0;
        }

        .nav-item {
            border-bottom: solid 1px var(--F1F1F1color);
            padding: 15px 0 15px 0;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
            }
        }
    }
}

/*=========== {___booking Page___} ===========*/
.bookingPage {
    position: relative;

    .card {
        // border: solid 1px var(--blackcolor);
        // box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
        border-radius: 16px;

        .card-header {
            border: none;
            border-bottom: solid 1px rgb(207, 207, 207);
            background-color: transparent;
            margin: 0;

            h5.titlehead {
                font-weight: 600;
                font-size: 18px;
                line-height: 28px;
                color: #505050;
            }

            .iconlogo1 {
                width: 100%;
                // height: 100%;
                // max-width: 115px;
                // min-width: 40px;
                // max-height: 40px;
                // min-height: 40px;
                // overflow: hidden;
                // display: flex;
                // align-items: center;
                // justify-content: center;
                // border: solid 2px var(--fullblackcolor);
                // border-radius: 100px;

                &.O3 {
                    transform: translate(-10px, 0px);
                }

                &.O2 {
                    transform: translate(10px, 0px);
                }

                &.O1 {
                    transform: translate(27px, 0px);
                }
            }
        }

        .table {
            .first-td {
                max-width: 150px;
                width: 120px;
            }

            tr td {
                vertical-align: middle;
                font-size: 14px;
                font-weight: 500;
                max-width: 100px;
            }
        }
    }
}

/*=========== {___zoom-in-zoom-out___} ===========*/
.table {
    &.colored {
        tr {
            border: 1px dashed #c9c9c9;
            background: #f1faff;

            &:hover {
                background-color: rgba(145, 158, 171, 0.08);
            }
        }
    }

    &.allView {
        .thead-dark th {
            background-color: #F4F6F8;
            font-size: 14px;
            font-weight: 500;
            border: none;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        tr {
            &:hover {
                background-color: rgba(145, 158, 171, 0.08);
            }

            // h5 {
            //     &:hover {
            //         text-decoration: underline;
            //     }
            // }
        }

        td {
            padding: 0 0.5rem !important;
        }

        td,
        th {
            color: var(--blackcolor);
        }

        tbody {
            td {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                vertical-align: middle;
                border: none;
            }
        }
    }

    &.reports {
        .thead-dark th {
            background-color: #F4F6F8;
            font-size: 14px;
            font-weight: 600;
            color: black;
            border: 1px solid rgb(235, 235, 235);
        }

        tbody {
            td {
                color: #212B36;
                font-style: normal;
                font-weight: normal;
                font-size: 13.5px;
                vertical-align: middle;
                border: none;
                padding: 4px 5px;
                margin: 0;
                border: 1px solid rgb(235, 235, 235);
            }
        }
    }
}

.booking-table {
    border-collapse: separate;
    border-spacing: 0 5px;

    th {
        background-color: var(--bluecolor);
        color: white !important;
    }

    th {
        padding: 2px 0.5rem;
        font-size: 13px;
        font-weight: 600;
    }

    tr td {
        border-top: 1px dashed #c9c9c9 !important;
        border-bottom: 1px dashed #c9c9c9 !important;
    }

    // .selected-tr td {
    //     border-color: #000000 !important;
    //     transition: all 0.6s;
    // }

    tr td:first-child {
        border-left: 1px dashed #c9c9c9;
    }

    tr td:last-child {
        border-right: 1px dashed #c9c9c9;
    }

    // tr td:first-child {
    //     border-bottom-left-radius: 6px;
    // }

    // tr td:last-child {
    //     border-bottom-right-radius: 6px;
    // }

    // tr td {
    //     border-top-style: solid;
    // }

    // tr td:first-child {
    //     border-left-style: solid;
    // }

    .pnrsub {
        opacity: 0;
        transition: opacity 0.5s ease;
    }

    .pnrsub-show {
        opacity: 1;
    }

    .subpart-row-entering,
    .subpart-row-exiting,
    .subpart-row-exited {
        transition: all 0.6s;
        display: none;
    }

    .subpart-row-entered {
        transition: all 0.6s;
        display: table-row;
    }
}

/*=========== {___zoom-in-zoom-out___} ===========*/
.airlinelogo {
    // width: 100%;
    height: 30px;
    // position: relative;
    max-height: 30px;

    // max-height: 40px;
    // min-width: 40px;
    // min-height: 40px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // overflow: hidden;
    .img-fluid {
        max-height: -webkit-fill-available;
    }
}

.AllPhoto {
    width: 100%;
    height: 100%;
    position: relative;
    max-width: 40px;
    max-height: 40px;
    min-width: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 100px;
    transition: all .6s;

    &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 3;
        border-radius: 100px;
        border: solid 2px var(--greencolor);
        opacity: 0;
        transition: all .6s;
    }
}

/*-----------------------signin, signup, forgot ----------------------*/

.limiter {
    width: 100%;
    margin: 0 auto;
}

.container-login100 {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: var(--bodycolor);
}

.wrap-login100 {
    width: 100%;
    background: var(--whitecolor);
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    flex-direction: row-reverse;
}

.txt1 {
    font-weight: 500;
    font-size: 14px;
    color: var(--blackcolor) !important;
}


/*==================================================================
  [ login more ]*/

.login100-more {
    width: calc(100% - 50%);
    // background-image: url(./images/bg.png);
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: cover;
    position: relative;
    // background-color: #ECFFED;
    background-color: #1262af;
    text-align: center;
    border-radius: 0px;
    flex: none;
    order: 0;
    flex-grow: 0;
    z-index: 0;

    .trevelAgency-logo {
        width: 100%;
        vertical-align: middle;
        margin: auto;
    }

    .loginTitle {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        color: var(--whitecolor);
        flex: none;
        order: 0;
        flex-grow: 0;
    }
}

@media (max-width: 1199px) {
    .login100-more {
        display: none;
    }
}


/*==================================================================
  [ dropzone-wrapper ]*/

.dropzone-wrapper {
    border: 2px dashed var(--paragraphcolor);
    color: #92b0b3;
    position: relative;
    border-radius: 10px;
    height: 150px;
}

.dropzone-desc {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    width: 40%;
    top: calc(100% - 80%);
    bottom: 0;
    margin: auto;
    font-size: 16px;
}

.dropzone,
.dropzone:focus {
    position: absolute;
    outline: none !important;
    width: 100%;
    height: 150px;
    cursor: pointer;
    opacity: 0;
}

.dropzone-wrapper:hover,
.dropzone-wrapper.dragover {
    background: var(--whitecolor);
}


/*==================================================================
  [ Form ]*/

.login100-form {
    width: 50%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    /* background-image: url(../images/icons/right-img.png);
    background-position: right top;
    background-repeat: no-repeat; */
    background-color: var(--whitecolor);
    padding: 55px 60px 55px 60px;

    .validate-form {
        .form-label {
            font-size: 14px;
            color: var(--paragraphcolor);
        }
    }
}

@media (max-width: 1199px) {
    .login100-form {
        width: 100%;
        padding: 15px;
    }
}

.login100-form-title {
    width: 100%;
    display: block;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
}

.login100-form-title p {
    font-size: 14px;
    color: var(--blackcolor);
    font-weight: 500;
}


/*------------------------------------------------------------------
  [ Input ]*/

.wrap-input100 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    width: 100%;
    height: 65px;
    position: relative;
    border: 1px solid rgba(145, 158, 171, 0.32);
    border-radius: 10px;
    margin-bottom: 0;

    .form-label {
        position: absolute;
        font-style: normal;
        font-weight: 400;
        z-index: 1;
        margin: 0;
        left: 15px;
        padding: 0 8px;
        background-color: var(--whitecolor);
        top: -5px;
        font-size: 12px;
        color: #919EAB;
        line-height: 12px;
    }
}

.label-input100 {
    font-size: 18px;
    color: var(--paragraphcolor);
    line-height: 1.2;
    display: block;
    position: absolute;
    pointer-events: none;
    width: 100%;
    padding-left: 24px;
    left: 0;
    top: 15px;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.input100 {
    display: block;
    width: 100%;
    background: var(--whitecolor);
    border: none;
    font-size: 16px;
    color: var(--paragraphcolor);
    line-height: 1.2;
    padding: 0 15px;
    border-radius: 8px;
}

input.input100 {
    height: 100%;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

// .imageuploader {
//     display: flex;
//     width: 150px;
//     height: 150px;
//     border: 1px solid #ced4da;
//     border-radius: 0.375rem;
//     transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
//     position: relative;
//     justify-content: center;
//     align-items: center;

//     input {
//         opacity: 0;
//         width: 150px !important;
//         height: 150px !important;
//         position: absolute;
//     }
// }

// .imgpreview {
//     display: flex;
//     border: 1px solid #ced4da;
//     border-radius: 0.375rem;
//     transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
//     justify-content: center;
//     align-items: center;
//     padding: 10px;

//     img {
//         display: flex;
//         width: 150px;
//         height: 150px;
//         border: 1px solid #ced4da;
//         border-radius: 0.375rem;
//         transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
//         position: relative;
//         justify-content: center;
//         align-items: center;
//     }

//     p {
//         margin: 0 10px;
//         padding: 0;
//     }

//     // .btn {
//     //     position: absolute;
//     //     right: 15px;
//     //     top: 15px;
//     // }
// }

input::file-selector-button {
    height: 130%;
}

/*---------------------------------------------*/

.focus-input100 {
    position: absolute;
    display: block;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    top: -1px;
    left: -1px;
    pointer-events: none;
    border: 2px solid var(--greencolor);
    border-radius: 10px;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-transform: scaleX(1.1) scaleY(1.3);
    -moz-transform: scaleX(1.1) scaleY(1.3);
    -ms-transform: scaleX(1.1) scaleY(1.3);
    -o-transform: scaleX(1.1) scaleY(1.3);
    transform: scaleX(1.1) scaleY(1.3);
}

.focus-input100-img {
    position: absolute;
    left: auto;
    right: 15px;
    opacity: 0.4;
    top: calc(100% - 74%);
    bottom: auto;
}

.input100:focus+.focus-input100 {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

.eff-focus-selection {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}


/* .input100:focus {
    height: 48px;
  } */

.input100:focus+.focus-input100+.label-input100 {
    top: 14px;
    font-size: 13px;
    color: var(--paragraphcolor);
}

.has-val {
    height: 48px !important;
}

.has-val+.focus-input100+.label-input100 {
    top: 14px;
    font-size: 13px;
    color: var(--paragraphcolor);
}

.contact100-form-checkbox {
    .input-checkbox100 {
        width: 24px;
        height: 24px;
        border-radius: 10px;
    }
}

// 
.login100-form-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    background: var(--blackcolor);
    font-size: 16px;
    color: var(--whitecolor);
    font-weight: 500;
    line-height: 1.2;
    text-transform: capitalize;
    letter-spacing: 1px;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.login100-form-btn:hover {
    background: var(--greencolor);
    color: var(--whitecolor);
}


// fontweight
.fontweight300 {
    font-weight: 300 !important;
}

.fontweight400 {
    font-weight: 400 !important;
}

.fontweight500 {
    font-weight: 500 !important;
}

.fontweight600 {
    font-weight: 600 !important;
}

.fontweight700 {
    font-weight: 700 !important;
}

.fontweight800 {
    font-weight: 800 !important;
}

/* ==========fontsize start========== */
.fontsize10 {
    font-size: 10px !important;
}

.fontsize11 {
    font-size: 11px !important;
}

.fontsize12 {
    font-size: 12px !important;
}

.fontsize13 {
    font-size: 13px !important;
}

.fontsize14 {
    font-size: 14px !important;
}

.fontsize16 {
    font-size: 16px !important;
}

.fontsize18 {
    font-size: 18px !important;
}

.fontsize20 {
    font-size: 20px !important;
}

.fontsize22 {
    font-size: 22px !important;
}

.fontsize24 {
    font-size: 24px !important;
}

.fontsize26 {
    font-size: 26px !important;
}

.fontsize28 {
    font-size: 28px !important;
}

@media(max-width:1199px) {
    .fontsize14 {
        font-size: 12px !important;
    }

    .fontsize16 {
        font-size: 14px !important;
    }

    .fontsize18 {
        font-size: 16px !important;
    }

    .fontsize20 {
        font-size: 18px !important;
    }
}

/*-----------------------modal----------------------*/
/*=========== {___modal___} ===========*/
.modal {
    &::after {
        content: '';
        position: fixed;
        height: 100%;
        width: 100%;
        background: var(--blackcolor);
        opacity: 0.2;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .modal-dialog {
        margin-right: auto;
        margin-left: auto;
    }

    .modal-content {
        border: none;
        border-radius: 15px;
        padding: 0px;
    }

    .modal-title {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: var(--blackcolor);
    }

    .modal-header {
        border: none !important;
    }
}

/*-----------------------webkit-scrollbar----------------------*/

::-webkit-scrollbar {
    width: 4px;
    height: 6px;
    border-radius: 10px;
    background-color: transparent;
    border-left: 0 solid transparent;
}

::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
    background: transparent;
    opacity: 1;
}

.footerbtn {
    &:hover {
        background: #f3f3f3 !important;
    }
}

.searchdropdow {
    border: none;
    border-radius: 6px;
    max-height: 200px;
    padding: 10px 20px;
    overflow: hidden;
    margin: 0;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 20%);

    &:hover {
        overflow-y: scroll;
    }

    .nav-item {
        background: #ebebeb;
        border-radius: 8px;
        cursor: pointer;

        &:hover {
            background: #bbbbbb;
        }
    }
}

.mainbanner {
    background: linear-gradient(79deg, #114F8B -3.12%, #2A9AD7 103.77%);
    position: relative;

    .bannerbox {
        display: flex;
        height: 640px;
        justify-content: center;
        align-items: center;
        gap: 43px;
        flex-direction: column;
    }

    .bannerbg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }

    .mainhedding {
        font-size: 61px;
        color: white;
    }

}

.whitebox {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    border-radius: 10px;
    background: #FFF;
    z-index: 1;

    .inputsbox {
        padding: 5px;
        align-items: flex-start;
        gap: 10px;
        flex: 1 0 0;
    }
}

.basic-btn {
    background-color: var(--mainorangecolor);
    border-radius: 4px;
    transition: all .6s;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--whitecolor);
    height: 100%;

    &:hover {
        background-color: var(--mainbluecolor);
        color: white;
    }
}

.h-56 {
    height: 56px;
}

.ticketslist {
    background: white;

    .cextorbox {
        padding: 7px 15px;
        border-radius: 6px;
        background: #ffffff;
        margin-right: 10px;
        color: white;
        font-size: 16px;
        font-weight: 500;
        transition: all 0.6s;
        border: 0.7px solid #CECECE;

        &:hover {
            background: var(--mainorangecolor);
        }
    }
}

.tobbar {
    padding: 14px !important;
    gap: 50px !important;
    font-size: 14px;
    font-weight: 600;
    background: black !important;
    color: white !important;
}

.ticketbox {
    display: flex;
    padding: 25px;
    justify-content: center;
    align-items: center;
    gap: 30px;
    align-self: stretch;
    border-radius: 6px;
    border: 0.7px solid #CECECE;
    background: #FFF;

    .airline {
        display: flex;
        max-width: 155px;
        align-items: center;
        gap: 7px;
    }

    .airdetails {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1 0 0;
    }

    .bookbtn {
        display: flex;
        min-width: 128px;
        justify-content: center;
        align-items: center;
    }
}

.bookingbox {
    border-radius: 6px;
    border: 0.7px dashed #CECECE;
    background: #ECFBFE;
    transition: all 0.6s;

    &:hover {
        background: #f2f2f2;
    }

    .airline {
        display: flex;
        max-width: 155px;
        align-items: center;
        gap: 7px;
    }

    .airdetails {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1 0 0;
    }

    .bookbtn {
        display: flex;
        min-width: 128px;
        justify-content: center;
        align-items: center;
    }
}

.publicfooter {
    background: linear-gradient(92deg, #0B5BA8 30.91%, #299BD8 100.98%);
    padding: 33px 0px;
}

.ticketbanner {
    // height: 150px;
    background: linear-gradient(92deg, #0B5BA8 30.91%, #299BD8 100.98%);
}

.herobg {
    background: linear-gradient(79deg, #114F8B -3.12%, #2A9AD7 103.77%);
    position: relative;
    padding-bottom: 50px;
}

.section-title {
    text-align: start;
    // padding-bottom: 30px;

    h2 {
        font-size: 13px;
        letter-spacing: 1px;
        font-weight: 700;
        padding: 8px 20px;
        margin: 0;
        background: #e7f1fd;
        color: var(--mainbluecolor);
        display: inline-block;
        text-transform: uppercase;
        border-radius: 50px;
    }

    h3 {
        margin: 15px 0 0 0;
        font-size: 32px;
        font-weight: 700;

        span {
            color: var(--mainorangecolor);
        }
    }

    p {
        margin: 0;
        font-weight: 600;

        &:hover {
            text-decoration: underline;
        }
    }
}

.airbox {
    border: 1px solid lightgray;
    border-radius: 12px;
    padding: 10px;
    margin-bottom: 30px;

    .airlineimg {
        border: 1px solid #cfcfcf;
        border-radius: 10px;
        overflow: hidden;
        object-fit: cover;
        width: 150px;
        height: 150px;
        margin-right: 16px;
    }

    .bankimg {
        border: 1px solid #cfcfcf;
        border-radius: 10px;
        overflow: hidden;
        object-fit: cover;
        width: 150px;
        height: 150px;
        margin-right: 16px;
        display: flex;
        align-items: center;
        padding: 10px;
    }
}


.copied {
    background: #1b7ec224;
    padding: 1px 10px;
    border-radius: 6px;
    color: #0055a7;
}

.notifications {
    .btn {
        background: transparent;
        width: 40px;
        height: 40px;
        border: none;
        padding: 0;
        margin: 0;
        position: relative;
        display: flex;
        align-items: center;

        .number {
            position: absolute;
            padding: 0px 5px;
            background: rgb(209, 54, 54);
            color: white;
            border-radius: 100px;
            top: 0px;
            right: 10px;
            font-size: 12px;
            width: -moz-fit-content;
            width: fit-content;
        }
    }
}

.notilist {
    border-bottom: 1px solid var(--RGBAcolor1);
    margin-bottom: 5px;
    transition: all 0.6s;

    &:hover {
        background: var(--RGBAcolor1);
        transition: all 0.6s;
        border-radius: 8px;

        .name {
            font-weight: 700 !important;
            color: var(--greencolor) !important;
        }
    }
}

// #hubspot-messages-iframe-container {
//     top: 0 !important;
// }

// .cstm-select {
//     position: absolute;
//     bottom: -10px;
//     z-index: 999999;
//     right: 0;
// }

.user-profile {
    .user-box {
        width: 60%;
        position: relative;
        margin-top: 40px;
        padding: 130px 24px 24px 24px;
        box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
        background: rgb(109 255 169 / 25%);
    }

    .user-head {
        position: absolute;
        left: 0;
        right: 0;
        top: -12.5%;
        display: flex;
        justify-content: center;

        .image {
            width: 100%;
            height: 100%;
            max-width: 120px;
            max-height: 120px;
            min-width: 120px;
            min-height: 120px;
            overflow: hidden;
            border-radius: 100px;
            transition: all 0.6s;
        }
    }
}

.howWeWork {
    h6.title {
        font-size: 35px;
        font-weight: 600;
        color: var(--blackcolor);
    }

    h6.titleInner {
        font-size: 25px;
        font-weight: 500;
        color: var(--blackcolor);
    }

    .card {
        background: linear-gradient(360deg, #1C1E53 10%, rgba(28, 30, 83, 0.42) 100%);
        border-radius: 0;
        border: none;
        padding-bottom: 45px;
    }
}

@media(max-width:991px) {
    .howWeWork h6.title {
        font-size: 25px;
    }

    .howWeWork h6.titleInner {
        font-size: 20px;
    }
}

@media(max-width:768px) {
    .howWeWork h6.title {
        font-size: 20px;
    }

    .howWeWork h6.titleInner {
        font-size: 16px;
    }
}

.about-sec1 {
    max-height: 450px;
    margin: 0 auto;
    align-items: end;
    justify-content: center;

    img {
        max-height: inherit;
        width: auto;
    }
}

.icon {
    img {
        width: 35px;
        height: 35px;
    }
}

.about-sec2 {
    max-height: 350px;
    border-radius: 18px;
    overflow: hidden;
    padding: 0;

    img {
        height: auto;
        width: 100%;
        object-fit: cover;
    }
}

.alert {
    background: black;
    width: max-content;
    padding: 3px 6px !important;
    border-radius: 8px;
    color: yellow;
}

.dash-count {
    font-weight: 500 !important;
    background: #ffa700;
    padding: 0 10px;
    border-radius: 7px;
    color: white !important;
    position: absolute;
    top: 10px;
    left: 10px;
}